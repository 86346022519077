import React from 'react'
import { Button } from '@chakra-ui/react'
import { DownloadRounded } from '../Icon'

const DownloadButton = ({ onClick, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<DownloadRounded />
			Download
		</Button>
	)
}

export default DownloadButton
