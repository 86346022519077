import React from 'react'
import FooterBase from '../../../../molecules/FooterBase'
import {
	Box,
	Heading,
	Divider,
	HStack,
	Button,
	Container,
	VStack,
	SimpleGrid,
	Flex,
	useDisclosure,
	LightMode,
} from '@chakra-ui/react'
import { useEventContext } from '../../../../../contexts/EventProvider'
import KeepsakeCards from '../../../Keepsake/atoms/KeepsakeCards'
import PublicHeader from '../../atoms/PublicHeader'
import { useRoleContext } from '../../../../../contexts/RoleProvider'
import NavBar from '../../../../atoms/NavBar'
import ExitButton from '../../../../atoms/nav-controls/ExitButton'
import DownloadButton from '../../../../atoms/nav-controls/DownloadButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import ModalDownloadVideo from '../../../../organisms/ModalDownloadVideo'
import BackButton from '../../../../atoms/nav-controls/BackButton'
import { DownloadVideoSelector } from '../../../Download/selectors/DownloadVideoSelector'
import ModalHidenVideo from '../../../../organisms/ModalHidenVideo'

export const PresentationNav = ({ event, onDownload, isOwner }) => {
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(event.uuid)
	const location = useLocation()

	const handleBack = () => {
		navigate(`${handlers.public_root}${location.search}`)
	}

	const handleExit = () => {
		navigate(`${handlers.root}`)
	}

	return (
		<NavBar pos={isOwner ? 'absolute' : 'relative'}>
			<HStack spacing={['4%', '1rem']}>
				<BackButton onClick={handleBack} />
				{isOwner && <ExitButton left text="Leave" onClick={handleExit} />}
			</HStack>

			<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
				<DownloadButton onClick={onDownload} />
			</HStack>
		</NavBar>
	)
}

const PublicOrRecipient = () => {
	const { role } = useRoleContext()
	const { event, isOwner } = useEventContext()

	const downloadModal = useDisclosure()

	return (
		<>
			<PresentationNav isOwner={isOwner} event={event} onDownload={downloadModal.onOpen} />

			<PublicHeader hideTitle />

			<Container maxW="container.lg" pt="2rem" pb="1rem" variant="main" px="1rem">
				<VStack spacing="2rem" w="full">
					{(event.allowKeepsakes || role == 'recipient') && (
						<>
							<Box mt="1rem" mb="1.5rem" textAlign="center">
								<Heading variant="hero">Order a Keepsake</Heading>
							</Box>
							<KeepsakeCards displayAsSlider={false} />
							<Flex w="full" alignItems="center">
								<Divider variant="dashed" my="1rem" borderColor="#314659" />
							</Flex>
						</>
					)}
					{(event.allowDownload || role == 'recipient') && (
						<>
							<Box mt="1rem" mb="1.5rem" textAlign="center">
								<Heading size="lg">Save the Video</Heading>
							</Box>
							<SimpleGrid columns={[1]} spacing="1rem" w="full">
								<DownloadVideoSelector event={event} onClick={downloadModal.onOpen} variant="outline" />
							</SimpleGrid>
						</>
					)}
				</VStack>
			</Container>
			<LightMode>
				<ModalDownloadVideo isOpen={downloadModal.isOpen} onClose={downloadModal.onClose} />
			</LightMode>
			<FooterBase />
			<ModalHidenVideo isOpen={event.isPrivate && role != 'recipient' && role != 'creator'} />
		</>
	)
}

export default PublicOrRecipient
